import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery, Link } from "gatsby"
import blogStyles from "./blog.module.scss"
import Head from "../components/head"

const PortfolioPage = () => {

  let contentfulData = useStaticQuery(graphql`
  query {
    allContentfulProjectPage {
      edges {
        node {
          title
          slug
          active
          linkToApp
          salesPitch
        }
      }
    }
  }`).allContentfulProjectPage.edges
  contentfulData = contentfulData.filter((project) => project.node.active)

  return (
    <Layout>
      <Head title="Portfolio" />
      <h1>Project Portfolio</h1>
      <p>Here I list the projects I have completed for you to review. Enjoy! Note that apps are hosted on spot instances and may not be active upon navigation. Wating no more than 30 seconds should allow you to load the app.</p>
      <ol className={blogStyles.posts}>
        {contentfulData.map(project => (
          <li className={blogStyles.post} key={project.node.slug}>
            <a href={project.node.linkToApp} target="_blank">
              <h2>{project.node.title}</h2>
              <p>{project.node.salesPitch}</p>
            </a>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default PortfolioPage
